import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCart: (state, action) => {
      console.log("action.payload", action.payload);
      let i = state.cart.findIndex((item) => item.id == action.payload.id);
      if (i >= 0) {
        state.cart[i].qty += 1;
        state.cart[i].totalPrice =
          state.cart[i].qty * state.cart[i].sellingPrice;
        state.cart[i].discountTotal =
          state.cart[i].qty * state.cart[i].totalDiscount;
        state.cart[i].mrpTotal = state.cart[i].qty * state.cart[i].mrp;
        localStorage.setItem("counterSaleCart", JSON.stringify(state.cart));
      } else {
        let tempProduct = {
          ...action.payload,
          qty: 1,
          totalPrice: action.payload.sellingPrice,
          discountTotal: action.payload.totalDiscount,
          mrpTotal: action.payload.mrp,
          extraInfo: [{ infoName: "", infoValue: "" }],
        };
        state.cart.push(tempProduct);
        localStorage.setItem("counterSaleCart", JSON.stringify(state.cart));
      }
    },
    minusCart: (state, action) => {
      const i = state.cart.findIndex((item) => item.id == action.payload.id);
      if (state.cart[i].qty == 1) {
        state.cart.splice(i, 1);
      } else {
        state.cart[i].qty -= 1;
        state.cart[i].totalPrice =
          parseInt(state.cart[i].qty) * parseInt(state.cart[i].sellingPrice);
        state.cart[i].discountTotal =
          parseInt(state.cart[i].qty) * parseInt(state.cart[i].totalDiscount);
        state.cart[i].mrpTotal =
          parseInt(state.cart[i].qty) * parseInt(state.cart[i].mrp);
      }
      localStorage.setItem("counterSaleCart", JSON.stringify(state.cart));
    },
    updatePrice: (state, action) => {
      const i = state.cart.findIndex((item) => item.id == action.payload.id);
      state.cart[i].sellingPrice = action.payload.newPrice;
      state.cart[i].totalDiscount = state.cart[i].mrp - action.payload.newPrice;
      state.cart[i].discount =
        (state.cart[i].totalDiscount / state.cart[i].mrp) * 100;
      state.cart[i].totalPrice =
        parseInt(state.cart[i].qty) * parseInt(state.cart[i].sellingPrice);
      state.cart[i].discountTotal =
        parseInt(state.cart[i].qty) * parseInt(state.cart[i].totalDiscount);
      state.cart[i].mrpTotal =
        parseInt(state.cart[i].qty) * parseInt(state.cart[i].mrp);
      localStorage.setItem("counterSaleCart", JSON.stringify(state.cart));
    },
    addExtraInfo: (state, action) => {
      const i = state.cart.findIndex((item) => item.id == action.payload.id);
      state.cart[i].extraInfo = [
        ...state.cart[i].extraInfo,
        { infoName: "", infoValue: "" },
      ];
      localStorage.setItem("counterSaleCart", JSON.stringify(state.cart));
    },
    updateExtraInfo: (state, action) => {
      const i = state.cart.findIndex((item) => item.id == action.payload.id);
      state.cart[i].extraInfo[action.payload.extraInfoIndex][
        action.payload.extraInfoKey
      ] = action.payload.extraInfoValue;
      localStorage.setItem("counterSaleCart", JSON.stringify(state.cart));
    },
    removeToCart: (state, action) => {
      const i = state.cart.findIndex((item) => item.id == action.payload.id);
      state.cart.splice(i, 1);
    },
    addFromAsync: (state, action) => {
      state.cart = action.payload;
    },
    clearCart: (state, action) => {
      state.cart = [];
      localStorage.setItem("counterSaleCart", JSON.stringify(state.cart));
    },
  },
});
export const {
  addCart,
  minusCart,
  removeToCart,
  addFromAsync,
  clearCart,
  updatePrice,
  addExtraInfo,
  updateExtraInfo,
} = cartSlice.actions;
export default cartSlice.reducer;
