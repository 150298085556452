import React, { useState } from "react";

const ThankYou = () => {
  return (
    <div
      className="container-fluid overflow-hidden py-2 d-flex flex-column flex-fill justify-content-between"
      style={{ background: "#3554C1", height: "100vh", position: "relative" }}
    >
      <div className="logo text-center py-3">
        <img src="/images/logo2.png" width={150} />
      </div>
      <div className="py-4">
        <h1 className="text-center fw-bold text-light">Thank You</h1>
        <h4 className="text-center fw-bold text-light my-2">
          To be a part of our family.
        </h4>
      </div>
      <div className="text-center p-5">
        <img
          className="rounded shadow"
          src="/images/success-registration.gif"
          width={"90%"}
        />
      </div>
      <div className="text-center fw-bold text-light">
        <p>
          Your Login credentials has been sent to your registered email address
        </p>
      </div>
      <small className="text-light text-center ">
        Powerd by Getmii Smart India Pvt Ltd
      </small>
    </div>
  );
};

export default ThankYou;
