import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Container, Header, Sidebar, Sidenav, Content, Nav } from "rsuite";
import { nav } from "../data/nav";
import AppContent from "./AppContent";
import { FaArrowLeft, FaTimes } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import {
  IoHomeOutline,
  IoReaderOutline,
  IoGridOutline,
  IoAlbumsOutline,
  IoPersonCircleOutline,
  IoHome,
  IoReader,
  IoGrid,
  IoAlbums,
  IoPersonCircle,
} from "react-icons/io5";
import { GoBell } from "react-icons/go";

const ProtectedLayout = () => {
  const navigate = useNavigate();
  const [expand, setExpand] = useState(false);
  const [mobile, setMobile] = useState(false);
  const { user, logout } = useAuth();
  const location = useLocation();
  const pathname = location.pathname;

  const handleResize = () => {
    if (window.innerWidth < 920) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  // create an event listener
  useEffect(() => {
    if (window.innerWidth < 920) {
      setMobile(true);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  if (!user) {
    return <Navigate to="/login" />;
  }

  console.log("pathname", pathname);

  return (
    <Container>
      <Sidebar
        style={{ display: "flex", flexDirection: "column" }}
        width={expand ? 260 : 56}
        collapsible
        onMouseOver={() => {
          setExpand(true);
        }}
        onMouseOut={() => {
          setExpand(false);
        }}
      >
        <Sidenav expanded={expand} appearance="subtle">
          <div
            id="sidebarHeader"
            className="d-flex w-100 justify-content-between align-items-center border-bottom"
          >
            <img
              src={expand ? "/images/logo.png" : "/images/minilogo.png"}
              width={expand ? 100 : 40}
            ></img>
          </div>
          <Sidenav.Body>
            <Nav>
              {nav.map((item, i) => {
                if (item.menu) {
                  return (
                    <Nav.Menu
                      key={i}
                      eventKey={i + 1}
                      title={item.title}
                      icon={item.icon}
                      placement="rightStart"
                    >
                      {item.menu.map((item2, j) => {
                        return (
                          <Nav.Item
                            key={j}
                            eventKey={j + 1}
                            onClick={() => {
                              navigate(item2.link);
                              setExpand(false);
                            }}
                          >
                            {item2.title}
                          </Nav.Item>
                        );
                      })}
                    </Nav.Menu>
                  );
                } else {
                  return (
                    <Nav.Item
                      key={i}
                      eventKey={i + 1}
                      icon={item.icon}
                      onClick={() => {
                        navigate(item.link);
                        setExpand(false);
                      }}
                    >
                      {item.title}
                    </Nav.Item>
                  );
                }
              })}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </Sidebar>
      <div
        hidden={expand ? false : true}
        onClick={() => setExpand(false)}
        className="overlay"
        style={{
          height: "100vh",
          width: "100vw",
          position: "absolute",
          background: "rgba(0,0,0,.5)",
          zIndex: 6,
        }}
      ></div>
      <Container>
        <Header>
          <div className="ps-3 d-flex align-items-center">
            {pathname != "/" && (
              <span className="pe-auto">
                <FaArrowLeft size={20} onClick={() => navigate(-1)} />
              </span>
            )}
            <p className="fs-4 ms-3">
              {pathname == "/"
                ? "Dashboard"
                : pathname.split("/")[1].replaceAll("-", " ").toUpperCase()}
            </p>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            {/* <div className="me-2" style={{ cursor: "pointer" }}>
              Plan : {user.planName}
            </div> */}
            {/* <div style={{ cursor: "pointer" }} className="notificationBell">
              <Whisper
                trigger="click"
                placement={"leftStart"}
                speaker={
                  <Popover arrow={false} title="Title">
                    <p>This is a Popover </p>
                    <p>Notification</p>
                  </Popover>
                }
              >
                <Button
                  size="xs"
                  appearance="primary"
                  className="notificationButton"
                >
                  <GoBell size={mobile ? 25 : 35} />
                </Button>
              </Whisper>
            </div> */}
            <div style={{ cursor: "pointer" }} className="logoutDesktop">
              <button onClick={logout} className="btn btn-sm btn-danger">
                Logout
              </button>
            </div>
            <div style={{ cursor: "pointer" }}>
              <button onClick={logout} className="btn text-light">
                <MdLogout size={30} />
              </button>
            </div>
          </div>
        </Header>
        <Content className="mainContent">
          <AppContent />
        </Content>
        {mobile &&
          (pathname == "/" ||
            pathname == "/orders" ||
            pathname == "/products" ||
            pathname == "/manage" ||
            pathname == "/my-shop-setting") && (
            <div className="bottonNavigation shadow">
              <div className="tabDiv" onClick={() => navigate("/")}>
                <div className="tabIcon">
                  {pathname == "/" ? (
                    <IoHome size={25} color={"#33479e"} />
                  ) : (
                    <IoHomeOutline size={25} color={"black"} />
                  )}
                </div>
                <div className="tabName">Home</div>
              </div>
              <div className="tabDiv" onClick={() => navigate("/orders")}>
                <div className="tabIcon">
                  {pathname == "/orders" ? (
                    <IoReader size={25} color={"#33479e"} />
                  ) : (
                    <IoReaderOutline size={25} color={"black"} />
                  )}
                </div>
                <div className="tabName">Orders</div>
              </div>
              <div className="tabDiv">
                <div className="tabIcon" onClick={() => navigate("/products")}>
                  {pathname == "/products" ? (
                    <IoGrid size={25} color={"#33479e"} />
                  ) : (
                    <IoGridOutline size={25} color={"black"} />
                  )}
                </div>
                <div className="tabName">Products</div>
              </div>
              <div className="tabDiv">
                <div className="tabIcon" onClick={() => navigate("/manage")}>
                  {pathname == "/manage" ? (
                    <IoAlbums size={25} color={"#33479e"} />
                  ) : (
                    <IoAlbumsOutline size={25} color={"black"} />
                  )}
                </div>
                <div className="tabName">Manage</div>
              </div>
              <div className="tabDiv">
                <div
                  className="tabIcon"
                  onClick={() => navigate("/shop-profile")}
                >
                  {pathname == "/shop-profile" ? (
                    <IoPersonCircle size={25} color={"#33479e"} />
                  ) : (
                    <IoPersonCircleOutline size={25} color={"black"} />
                  )}
                </div>
                <div className="tabName">Profile</div>
              </div>
            </div>
          )}
      </Container>
    </Container>
  );
};

export default React.memo(ProtectedLayout);
