import "./App.scss";
import "./login.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/rsuite.min.css";
import "leaflet/dist/leaflet.css";
import "react-quill/dist/quill.snow.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Routes, Route } from "react-router-dom";
import { lazy, Suspense, useEffect } from "react";
import ProtectedLayout from "./components/ProtectedLayout";
import HomeLayout from "./components/HomeLayout";
import { Loader } from "rsuite";
import { useDispatch } from "react-redux";
import { addFromAsync } from "./redux/cartSlice";
import ThankYou from "pages/ThankYou";
const loading = (
  <div className="fullLoader">
    <Loader />
  </div>
);
const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const ShopQrCode = lazy(() => import("./pages/shopQrCode/ShopQrCode"));

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      var d = window.localStorage.getItem("counterSaleCart");
      console.log("cart local", JSON.parse(d));
      if (d) {
        console.log("cart local", JSON.parse(d));
        dispatch(addFromAsync(JSON.parse(d)));
      } else {
        dispatch(addFromAsync([]));
      }
    };
    getData();
  }, []);

  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Route>
        <Route path="/shop-qr-code/:shopNumber" element={<ShopQrCode />} />
        <Route path="*" element={<ProtectedLayout />} />
      </Routes>
    </Suspense>
  );
}

export default App;
