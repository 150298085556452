import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../routes";
import { Loader } from "rsuite";
import { useState } from "react";

const loading = <Loader backdrop content="loading..." vertical />;

const AppContent = () => {
  const [mobile, setMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 920) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  // create an event listener
  useEffect(() => {
    if (window.innerWidth < 920) {
      setMobile(true);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <Suspense fallback={loading}>
      <Routes>
        {routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element mobile={mobile} />}
              />
            )
          );
        })}
      </Routes>
    </Suspense>
  );
};

export default AppContent;
