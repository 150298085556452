import {
  MdDeliveryDining,
  MdDashboard,
  MdOutlineCampaign,
  MdOutlineShoppingBag,
  MdOutlineLocalOffer,
} from "react-icons/md";
import { BsGear } from "react-icons/bs";
import { BsHeart } from "react-icons/bs";
import { IoSearchOutline, IoBriefcaseOutline } from "react-icons/io5";

import { RiUserHeartLine } from "react-icons/ri";

export const nav = [
  {
    title: "Dashboard",
    link: "/",
    icon: <MdDashboard />,
  },
  {
    title: "Shop Management",
    icon: <BsGear />,
    menu: [
      {
        title: "My Slider",
        link: "/my-shop-slider",
      },
      {
        title: "Shop Setting",
        link: "/my-shop-setting",
      },
    ],
  },
  {
    title: "My Campaign",
    icon: <MdOutlineCampaign />,
    link: "/my-campaign",
  },
  {
    title: "Product Master",
    icon: <BsHeart />,
    menu: [
      {
        title: "Products",
        link: "/products",
      },
    ],
  },
  {
    title: "Orders Master",
    icon: <MdOutlineShoppingBag />,
    menu: [
      {
        title: "Orders",
        link: "/orders",
      },
    ],
  },
  //CAMPAIGN
  {
    title: "City Campaing",
    link: "/campaign",
    icon: <MdOutlineCampaign />,
  },
  {
    title: "City Demand",
    link: "/city-demand",
    icon: <IoSearchOutline />,
  },
  {
    title: "My Followers",
    link: "/my-followers",
    icon: <RiUserHeartLine />,
  },
  {
    title: "My Offers",
    link: "/my-offers",
    icon: <MdOutlineLocalOffer />,
  },
  {
    title: "My Delivery Boy",
    link: "/delivery-boy-list",
    icon: <MdDeliveryDining className="rs-sidenav-item-icon rs-icon" />,
  },
  {
    title: "My Jobs",
    link: "/my-jobs",
    icon: <IoBriefcaseOutline className="rs-sidenav-item-icon rs-icon" />,
  },
];
